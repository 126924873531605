import { Injector }                                              from '@angular/core';
import { DataEntrySaveMethod }                                   from '@cs/components/data-grid';
import { isNull }                                                from '@cs/core/utils';
import { DataEntryDynamicPageButtonsAgent, DataEntryStateQuery } from '@cs/performance-manager/data-entry';
import { BottomBarQuery, DynamicButtonRegistration }             from '@cs/performance-manager/shared';

export class CustomerDynamicButtonsAgent extends DataEntryDynamicPageButtonsAgent {

	setupButtons(): void {
		super.setupButtons();


		this.addButton(DynamicButtonRegistration.createServerButton({
																																	buttonId:          'PlaceOrder',
																																	isVisible:         (button: DynamicButtonRegistration,
																																											injector: Injector, pageComponent: unknown) => {
																																		const buttonBarQuery        = injector.get(BottomBarQuery);
																																		const dataEntryStateService = injector.get(DataEntryStateQuery);
																																		return (dataEntryStateService.getValue().saveMethod === DataEntrySaveMethod.TopButtonBar) &&
																																			(buttonBarQuery.getValue().unsavedChanges === 0 || isNull(buttonBarQuery.getValue().unsavedChanges));
																																	},
																																	executeTransition: (button: DynamicButtonRegistration, injector: Injector,
																																											pageComponent: unknown) =>
																																											 DataEntryDynamicPageButtonsAgent.executeTransitionEntryGridButton(button, injector, pageComponent)
																																}
		));

	}
}
